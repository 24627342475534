<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="berechtigungen.m_dokumente.update"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />
                    {{ $t("global.tolist") }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    v-if="berechtigungen.m_dokumente.delete"
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <container-headline
            :headline="$t('global.documents')"
            :col="6"
          ></container-headline>

          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6" v-if="dokument.person">
                    <single-select-person
                      id="person"
                      :person="dokument.person"
                      :label="$t('global.person')"
                      :allowOpen="true"
                      :editable="true"
                      :required="false"
                      @confirmed="setPerson"
                    />
                  </div>
                  <div class="col-xl-6" v-if="dokument.firma">
                    <single-select-firma
                      id="dokumentFirma"
                      :firma="dokument.firma"
                      :editable="true"
                      :allowOpen="true"
                      :label="$t('global.company')"
                      @confirmed="setFirma"
                    />
                  </div>
                </div>

                <div class="row" v-if="dokument.foreigntable != ''">
                  <div class="col-xl-8 mb-3">
                    <button
                      class="btn btn-primary"
                      @click="oeffneForeignDocument"
                      v-if="dokument.foreigntable == 'lead'"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-tachometer-alt"
                        class="mr-2"
                      />
                      <span>{{ $t("global.openlead") }}</span>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <input
                        v-model.trim="dokument.erstellt"
                        class="form-control"
                        placeholder=" "
                        readonly
                      />
                      <label>{{ $t("global.created") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <single-select-person
                      id="ersteller"
                      :person="dokument.ersteller"
                      :label="$t('global.creator')"
                      :allowOpen="true"
                      :editable="false"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="dokument.bezeichnung"
                        class="form-control"
                        placeholder=" "
                        :editable="true"
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <container-headline
            :headline="$t('global.document')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-12">
                    <button
                      class="btn btn-primary"
                      @click="oeffnePerson(dokument)"
                    >
                      {{ $t("global.opendocument") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <loeschen-modal
      id="dokument-loeschen-modal"
      @confirmed="dokumentLoeschen"
    />

    <loading-overlay v-show="loading"></loading-overlay>

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    ></pdf-layer>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";
import LoeschenModal from "@/components/Modals/LoeschenModal";

//import PDFJSViewer from "@/components/global/PDFJSViewer.vue";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";
import server from "@/server";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";

export default {
  name: "Dokument",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    LoadingOverlay,
    SingleSelectPerson,
    SingleSelectFirma,
    LoeschenModal,
    UnsavedChangesModal,
  },
  mixins: [page, ChangeWatcherNew],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dokument: {
        person: {
          personName: this.$t("global.notlinked"),
        },
        foreigntable: null,
        foreignkey: null,
      },
      loading: false,
      pdfFileName: "",
      pdfPath: "",
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.id) {
        return this.$t("global.document");
      } else {
        return this.$t("global.newdocument");
      }
    },
  },
  created() {
    Api.get("dokument/", { params: { id: this.id } }).then((response) => {
      this.initializeDokument(response.data);
    });
  },
  mounted: function () {},
  methods: {
    abbrechen() {
      this.$router.push({ name: "Dokumentenliste" });
    },

    oeffnePerson(d) {
      this.pdfPath = server.url + d.dokument;
      this.$refs.pdfLayer.show();
    },

    oeffneForeignDocument() {
      if (this.dokument.foreigntable && this.dokument.foreignkey) {
        switch (this.dokument.foreigntable) {
          case "lead":
            this.$router.push({
              name: "lead",
              params: { id: this.dokument.foreignkey, anzeige: 0 },
            });
            break;
        }
      }
    },

    initializeDokument(dokument) {
      if (dokument) {
        this.dokument = dokument;
        if (dokument.name != null)
          if (dokument.name.toUpperCase().includes(".PDF"))
            this.pdfFileName =
              Api.defaults.defaultURL + this.dokument.dokument + "/";

        //this.initializeChangewatcher(["dokument"]);
      } else {
        console.error("dokument ist null");
      }
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      /*var json = {
        name: this.name,
        document: this.dokument,
      };*/
      let json = {};
      this.$set(json, "document", this.dokument);

      if (!this.id) {
        Api.post("dokument/", json)
          .then((response) => {
            this.initializeDokument(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.documentsuccessfullysaved"),
            });

            this.$router.replace({
              name: "DokumentMitId",
              params: { id: response.data.id },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("dokument/", json, { params: { id: this.dokument.id } })
          .then((response) => {
            this.initializeDokument(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.documentsuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    dokumentLoeschen() {
      Api.delete("dokument/", {
        params: { ids: this.id },
      }).then(() => {
        this.$router.push({ name: "Dokumentenliste" });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "dokument-loeschen-modal");
    },

    setPerson(person) {
      this.dokument.person = person;
    },
    setFirma(firma) {
      this.dokument.firma = firma;
    },
  },
};
</script>

<style></style>
